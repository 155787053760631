<template>
    <CModal :visible="showModal" size="xl" alignment="top" @close="showModal=false" backdrop="static">
        <CModalHeader>
            <CModalTitle>Artikel bearbeiten</CModalTitle>
        </CModalHeader>
        <CModalBody>
            <div class="row">
                <div class="col mb-3">
                    <input-field name="artnr" label="Artikelnummer" :class="disabled" readonly disabled v-model="article.artnr" :errors="errors" placeholder="wird autom. generiert"></input-field>
                </div>
                <div class="col mb-3">
                    <input-field name="title" required label="Bezeichnung" v-model="article.title" :errors="errors"></input-field>
                </div>
                <div class="col mb-3">
                    <input-field name="version" required label="Version" v-model="article.version" :errors="errors"></input-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#price-tab-pane"
                                    type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Preise
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#settings-tab-pane"
                                    type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Eigenschaften
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#options-tab-pane"
                                    type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Optionen/Regeln
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#notice-tab-pane"
                                    type="button" role="tab" aria-controls="notice-tab-pane" aria-selected="true">Notizen
                            </button>
                        </li>
                    </ul>
                    <div class="tab-content flex-grow-1 border border-top-0 p-3" id="myTabContent" style="min-height: 0; overflow: auto;">
                        <div class="tab-pane h-100 fade show active " id="price-tab-pane" role="tabpanel" aria-labelledby="overview-tab"
                             tabindex="0">
                            <div class="row">
                                <div class="col-8">
                                    <div class="mb-3">
                                        <input-currency name="vkMin" required label="VK min."  v-model="article.vkMin" :errors="errors"></input-currency>
                                    </div>
                                    <div class="mb-3">
                                        <input-currency name="vkMinHB" required label="VK min. Händler/Behörde"  v-model="article.vkMinHB" :errors="errors"></input-currency>
                                    </div>
                                    <hr>
                                    <div class="mb-3">
                                        <input-currency name="priceEK" required label="EK max."  v-model="article.priceEK" :errors="errors"></input-currency>
                                    </div>

                                </div>
                                <div class="col mb-3">
                                  <div class="card h-100">
                                      <div class="card-header">
                                          <b>Optionen</b>
                                      </div>
                                      <div class="card-body">
                                        <div class="input-group mb-3">
                                          <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" id="isActive" v-model="article.isActive" aria-label="Checkbox for following text input">
                                          </div>
                                          <label class="input-group-text flex-grow-1" for="isActive"> Artikel ist aktiv </label>
                                        </div>
                                        <div class="input-group">
                                          <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" id="commissionable" v-model="article.commissionable" aria-label="Checkbox for following text input">
                                          </div>
                                          <label class="input-group-text flex-grow-1" for="commissionable"> Artikel ist provisionsfähig</label>
                                        </div>
                                      </div>
                                  </div>

                                </div>
                                <!--<div class="col">
                                    <div class="mb-3">
                                        <input-currency name="priceA" label="Shoppreis Endkunden"  readonly disabled v-model="priceA" :errors="errors"></input-currency>
                                    </div>
                                    <div class="mb-3">
                                        <input-currency name="priceB" label="Shoppreis Händler"  readonly disabled v-model="priceB"  :errors="errors"></input-currency>
                                    </div>
                                    <div class="mb-3">
                                        <input-currency name="priceC" label="Shoppreis Behörde"  readonly disabled v-model="priceC"  :errors="errors"></input-currency>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <div class="tab-pane h-100 fade show" id="settings-tab-pane" role="tabpanel" aria-labelledby="overview-tab"
                             tabindex="0">
                            <div class="row">
                                <div class="col mb-3">
                                    <select-field name="product_type" label="Produktart" required v-model="article.product_type" :errors="errors">
                                      <optgroup label="Software">
                                        <option value="license">Lizenz</option>
                                        <option value="disk">MediaKit</option>
                                      </optgroup>
                                      <optgroup label="Andere">
                                          <option value="hardware">Hardware</option>
                                          <option value="embodiment">Verkörperung</option>
                                      </optgroup>
                                    </select-field>
                                </div>
                                <div class="col mb-3">
                                  <select-field name="license_type" label="Lizenztyp" required v-model="article.license_type" :errors="errors">
                                    <option value="volume">Volumenlizenz</option>
                                    <option value="single user">Arbeitsplatzlizenz</option>
                                    <option value="other">anderes</option>
                                  </select-field>
                                </div>
                                <div class="col mb-3">
                                    <input-field name="producer" required label="Hersteller" v-model="article.producer" :errors="errors"></input-field>
                                </div>
                            </div>
                            <div class="row">

                              <div class="col mb-3">
                                <select-field name="mediakit_id" label="passendes Media Kit" :disabled="article.product_type == 'disk'" v-model="article.mediakit_id" :errors="errors">
                                    <template v-for="mediakit in mediakits" :key="mediakit.id">
                                      <option :value="mediakit.id">{{mediakit.artnr}} | {{mediakit.title}} {{mediakit.version}}</option>
                                    </template>
                                </select-field>
                              </div>
                                <div class="col mb-3">
                                    <select-field name="category_id" label="Kategorie" v-model="article.category_id" :errors="errors">
                                      <option v-for="category in $store.state.categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                                    </select-field>
                                </div>
                                <div class="col mb-3">
                                    <input-integer name="stockWarning" label="Mindestbestand" v-model="article.stockWarning" :errors="errors">
                                      <template #behind><div class="input-group-text">Stk.</div></template>
                                    </input-integer>
                                </div>
                            </div>
                            <div class="row">
                              <div class="col mb-3">
                                <input-field name="supplierReferenceNumber" label="Bestellnummer Lieferant" max-length="128" v-model="article.supplierReferenceNumber" :errors="errors"></input-field>
                              </div>
                            </div>
                        </div>
                        <div class="tab-pane h-100 fade show" id="options-tab-pane" role="tabpanel" aria-labelledby="overview-tab"
                             tabindex="0">
                            <div class="row">
                                <div class="col">
                                    <div class="card h-100">
                                      <div class="card-header"><b><i class="fa-solid fa-file-pdf"></i> Kundenhinweise und Anhänge</b></div>
                                      <div  class="card-body">
                                        <div class="input-group mb-3">
                                          <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" id="pdf_standard" v-model="article.pdf_standard" aria-label="Checkbox for following text input">
                                          </div>
                                          <label class="input-group-text flex-grow-1" for="pdf_standard"><i class="fa-solid fa-triangle-exclamation fa-fw me-1"></i> erzwingt Zusatztext (Standard) auf dem LK-PDF</label>
                                        </div>
                                        <div class="input-group mb-3">
                                          <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" id="pdf_cal" v-model="article.pdf_cal" aria-label="Checkbox for following text input">
                                          </div>
                                          <label class="input-group-text flex-grow-1" for="pdf_cal"><i class="fa-solid fa-triangle-exclamation  fa-fw me-1"></i> erzwingt Zusatztext (CAL) auf dem LK-PDF</label>
                                        </div>
                                        <hr/>
                                        <div class="input-group mb-3">
                                          <div class="input-group-text">
                                            <input class="form-check-input mt-0" type="checkbox" id="pdf_installation" v-model="article.pdf_installation" aria-label="Checkbox for following text input">
                                          </div>
                                          <label class="input-group-text flex-grow-1" for="pdf_installation"><i class="fa-solid fa-file-pdf  fa-fw me-1"></i> Installationsanleitung wird angehängt</label>
                                        </div>
                                      </div>
                                    </div>

                                </div>
                                <div class="col">
                                  <div class="card h-100">

                                          <div class="card-header">
                                              <b><i class="fa-solid fa-arrow-progress"></i> Regeln für autom. Zuordnung</b>
                                          </div>
                                          <div class="card-body">
                                            <div class="mb-3">
                                                <div class="input-group">
                                                  <div class="input-group-text">
                                                    <input class="form-check-input mt-0" type="checkbox" id="noKeyRequired" v-model="article.noKeyRequired" aria-label="Checkbox for following text input">
                                                  </div>
                                                  <label class="input-group-text flex-grow-1" for="noKeyRequired"> Artikel benötigt keinen Lizenzschlüssel</label>
                                                </div>
                                            </div>
                                            <div class="mb-3" v-if="!article.noKeyRequired">
                                                <div class="input-group">
                                                  <div class="input-group-text">
                                                    <input class="form-check-input mt-0" type="checkbox" id="manuelKeySelection" v-model="article.manuelKeySelection" aria-label="Checkbox for following text input">
                                                  </div>
                                                  <label class="input-group-text flex-grow-1" for="manuelKeySelection"> manuelle Key-Auswahl aktivieren</label>

                                                </div>
                                                <div id="passwordHelpBlock" class="form-text">
                                                  Bei Aktivierung muss die Key-Zuweisung immer manuell erfolgen.
                                                </div>
                                            </div>


                                            <div class="mb-3" v-if="!article.noKeyRequired && !article.manuelKeySelection">
                                              <input-integer name="manuelKeySelectionByAmount" :options="options" :disabled="!isManuelKeySelectionByAmountActive" v-model="article.manuelKeySelectionByAmount" :errors="errors" label="Manuelle Key-Auswahl, wenn mehr als X Lizenzen bestellt" >
                                                <div class="input-group-text">
                                                  <input class="form-check-input mt-0" type="checkbox" v-model="isManuelKeySelectionByAmountActive">
                                                </div>
                                                <template #behind><div class="input-group-text">Stk.</div></template>
                                              </input-integer>
                                              <div id="passwordHelpBlock" class="form-text">
                                                Key muss manuell zugewiesen werden, wenn die Lizenzanzahl <u><b>größer</b></u> als die angegeben Anzahl ist.
                                              </div>
                                            </div>
                                            <hr/>
                                            <div class="mb-3">
                                              <input-integer name="minLicensce" :options="options" :disabled="!article.autostopAmountSmallerThan" v-model="article.minLicensce" :errors="errors" label="Manuelle Auftragsfreigabe aktiveren, wenn Mindestlizenzanzahl unterschritten wird" >
                                                <div class="input-group-text">
                                                  <input class="form-check-input mt-0" type="checkbox" v-model="article.autostopAmountSmallerThan">
                                                  <!--<div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="article.autostopAmountSmallerThan">
                                                    <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input</label>
                                                  </div>-->
                                                </div>
                                                <template #behind><div class="input-group-text">Stk.</div></template>
                                              </input-integer>
                                              <div id="passwordHelpBlock" class="form-text">
                                                Auftrag muss manuell bestätigt werden, wenn die Lizenzanzahl die Mindestlizenzmenge unterschreitet (z.B. Windows Server).
                                              </div>
                                            </div>
                                          </div>
                                      </div>


                                </div>
                            </div>
                        </div>
                        <div class="tab-pane h-100 fade show" id="notice-tab-pane" role="tabpanel" aria-labelledby="overview-tab"
                             tabindex="0">
                            <text-area-field label="Notizen" v-model="article.notice" :errors="errors" ></text-area-field>
                        </div>
                    </div>
                </div>
            </div>


        </CModalBody>
        <CModalFooter>
            <div class="col text-end">
                <button class="btn btn-outline-dark btn-sm me-3" @click="showModal=false">Abbrechen</button>
                <button class="btn btn-primary btn-sm" @click="save()" v-if="action=='edit'">Speichern</button>
                <button class="btn btn-primary btn-sm" @click="create()" v-if="action=='create'">Erstellen</button>
            </div>
        </CModalFooter>
    </CModal>
</template>

<script>
import {CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle} from "@coreui/bootstrap-vue";
import {isDisabled} from "bootstrap/js/src/util";
import InputField from "@/components/inputField.vue";
import CompanyTabOverview from "@/pages/crm/CompanyTabOverview.vue";
import InputCurrency from "@/components/inputCurrency.vue";
import SelectField from "@/components/selectField.vue";
import TextAreaField from "@/components/textAreaField.vue";
import eventBus from "@/eventBus";
import InputInteger from "@/components/inputInteger.vue";

export default {
    name: "modalArticle",
    components: {
        InputInteger,
        TextAreaField,
        SelectField,
        InputCurrency,
        CompanyTabOverview,
        InputField,
        CModal, CModalBody,
        CModalFooter,
        CModalHeader,
        CModalTitle,
    },
    props: {
        shown: Boolean,
        modus: {
            type: Object,
            default(){
                return {
                    action: 'create',
                };
            },
        }
    },
    watch: {
        shown(value) {
            this.action = this.modus.action;
            this.loadMk();
            if(value === false) {
                this.reset();
            }
            else{
                this.$root.setModalHidden(false);

                if(this.action == 'edit'){

                    this.load();
                }
            }
            this.showModal = value;
        },
        showModal(value) {
            this.$emit('update:shown', value);
        }
    },
    computed:{
        priceA(){
            return this.article.vkMin * 1.05;
        },
        priceB(){
            return this.article.vkMinBH * 1.05;
        },
        priceC(){
            return (this.priceA - this.priceB) / 2 + this.priceB;
        },
        isManuelKeySelectionByAmountActive: {
            get(){
                return this.article.manuelKeySelectionByAmount && this.article.manuelKeySelectionByAmount > 0;
            },
            set(value){
                if(value){
                  this.article.manuelKeySelectionByAmount = 1;
                }
                else{
                  this.article.manuelKeySelectionByAmount = null;
                }
            }
        },
    },
    data() {
        return {
            action: 'create',
            showModal: false,
            errors: [],
            saving: false,
            article: {},
            mediakits: [],
            options: {
              "currency": "EUR",
              "currencyDisplay": "hidden",
              "precision": 0,
              "hideCurrencySymbolOnFocus": true,
              "hideGroupingSeparatorOnFocus": true,
              "hideNegligibleDecimalDigitsOnFocus": true,
              "autoDecimalDigits": false,
              "useGrouping": true,
              "accountingSign": false
            },
            articleDefault:{
                isActive: false,
                mediakit_id: null,
                artnr: '',
                title: '',
                version: '',
                producer: '',
                vkMin: 0.00,
                vkMinHB: 0.00,
                priceEK: 0.00,
                stockWarning: 0,
                manuelKeySelection: false,
                manuelKeySelectionByAmount: null,
                autostopAmountSmallerThan: false,
                pdf_standard: false,
                pdf_cal: false,
                pdf_installation: false,
                minLicensce: 1,
                noKeyRequired: false,
                notice: null,
                commissionable: false,
                category_id: null,
                customerReferenceNumber: '',
            }
        };
    },
    mounted() {
        this.article = {...this.articleDefault};
        this.showModal = this.shown;
    },
    methods:{
        reset(){
            this.errors = {};
            this.article = {...this.articleDefault};
        },
        loadMk(){
          let url = "/erp/articles/mediakits";

          this.axios.get(url).then(res => {
            this.mediakits = res.data;
          });
        },
        load(){
            let url = "/erp/articles/" + this.modus.article_id;

            this.axios.get(url).then(res => {
                this.article = res.data;
            });
        },
        create(){
          let url = "/erp/articles";
          this.errors = {};

          this.axios.post(url, this.article).then(res => {
            eventBus.$emit('article', {action: "update",  data: res.data.article});
            if(res.data.success){
              this.$toast.success('Artikel' + res.data.article.title.toString() + ' angelegt');
              this.$router.push({name: 'article', params: {id : res.data.article.id}});
              this.showModal = false;
            }
            this.reset();
          }).catch(error => {
            if (error.response && error.response.status === 422) {
              this.errors = error.response.data.errors || {};
              this.$toast.error(this.$t('common.inputError'));
            }
            else{
              this.$toast.error(this.$t('common.error'));
            }
            this.saving = this.savingNext = false;
          });

        },
        save(){
            let url = "/erp/articles/" + this.modus.article_id;
            this.errors = {};

            this.axios.put(url, this.article).then(res => {
                if(res.data.success === true){
                    this.$toast.success(this.$t('article.msg.updated', {articlename: this.article.title}));
                    eventBus.$emit('article', {action: "update",  data: res.data.data});
                    this.showModal = false;
                    this.reset();
                }
            })
            .catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    this.$toast.error(this.$t('common.inputError'));
                }
                else{
                    this.$toast.error(this.$t('common.error'));
                }
                this.saving = this.savingNext = false;
            });
        }
    }
}
</script>

<style scoped>

</style>